import React, { useEffect, useState } from 'react';

//css import
import './Wiki.css';

//lib import
import LinearProgress from '@mui/material/LinearProgress';
import { useParams } from 'react-router-dom';

//component import
import Footer from '../../global-components/Footer';
import Navbar from '../../global-components/Navbar';
import MarkdownComponent from './Components/MarkdownViewArea';
import NavigationBreadcrumbs from './Components/NavigationBreadcrumbs';
import NotFoundPage from './Components/NotFoundPage';
import TreeViewArea from './Components/TreeViewArea';

//provider import
import { WikiProvider } from '../../providers/API-providers/Wiki-provider';
import { LanguageService } from '../../providers/Language-services/Language-service';

//interfaces import
import { I_TreeView } from '../../providers/interfaces/interfaces';

const languageService = new LanguageService();
const wikiProvider = new WikiProvider();

let fetched = false;

interface I_Wiki {
  customBreadcrumbIndex?: number;
}

const Wiki: React.FunctionComponent<I_Wiki> = ({ customBreadcrumbIndex }) => {
  const [markdown, setMarkdown] = useState<string>('');
  const [treePath, setTreePath] = useState<string>('');
  const [isResources, setIsResources] = useState<boolean>(false);
  const [isPathValid, setIsPathValid] = useState<boolean>(true);
  const [links, setLinks] = useState<I_TreeView[]>([]);
  const params = useParams();
  let path = decodeURIComponent(window.location.pathname);
  path = path.replaceAll('-', ' ');

  useEffect(() => {
    setIsResources(path.includes('Resources'));
    fetched = false;
    checkForLanguageURL();
    clearPage();
    fetchWiki(path.substring(1));
    fetched = true;
  }, [path]);

  const checkForLanguageURL = (): void => {
    if (
      params.lang != languageService.getLanguage() &&
      params.lang != undefined &&
      (params.lang == 'en-us' || params.lang == 'cs-cz')
    ) {
      languageService.setLanguage(params.lang);
      location.reload();
    }
  };

  const clearPage = (): void => {
    setLinks([]);
    setTreePath('');
  };

  const fetchWiki = (path: string) => {
    wikiProvider
      .fetchWikiContent(path)
      .then((response) => {
        if (!response.ok) return false;
        return response.json();
      })
      .then((data) => {
        if (!data) throw 'error';
        setMarkdown(data.Content);
        setIsPathValid(data.IsValidPath);
        data.TableOfContent.forEach((element: I_TreeView) => {
          setTreePath(element.Path);
          setLinks((links) => [...links, element]);
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  if (!fetched) {
    return (
      <div id='wiki'>
        <Navbar />
        <div id='wiki-body'>
          <LinearProgress color='inherit' />
        </div>
      </div>
    );
  }

  const getWikiBody = (): JSX.Element => {
    if (!isPathValid) {
      return <NotFoundPage />;
    }
    return (
      <div className='row mt-5'>
        <div
          className={
            isResources
              ? 'd-none col-md-4 col-lg-3 col-12'
              : ' col-md-4 col-lg-3 col-12'
          }
          id='tree-area'
        >
          <TreeViewArea SubPage={links} Path={treePath} Content={markdown} />
        </div>
        <div
          className={
            isResources
              ? 'col-12 markdown-area'
              : 'col-md-6 col-lg-7 col-12 markdown-area'
          }
        >
          <MarkdownComponent markdown={markdown} pageURL={path} />
        </div>
      </div>
    );
  };

  return (
    <div id='wiki'>
      <Navbar />
      <div id='wiki-body'>
        <div className='container-fluid'>
          <div className='breadcrumbArea'>
            <NavigationBreadcrumbs
              customBreadCrumbIndex={customBreadcrumbIndex}
              isResources={isResources}
              pageURL={path}
            />
          </div>
          {getWikiBody()}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Wiki;

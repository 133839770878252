import React from 'react';

import { LanguageService } from '../../../providers/Language-services/Language-service';

const languageService = new LanguageService();

const NotFoundPage: React.FunctionComponent = () => {
  return (
    <div id='not-found-page'>
      <div className='not-found-body'>
        <div className='not-found-title'>
          {' '}
          {languageService.getLanguage() == 'cs-cz'
            ? '404 - stránka nebyla nalezena'
            : '404 - page not found'}
        </div>
        <div className='not-found-description'>
          {languageService.getLanguage() == 'cs-cz'
            ? 'Hmm, zdá se, že nemůžeme najít stránku, kterou hledáte. Vzhledem k tomu, že Seyfor Docs právě prochází restrukturalizací, mohla být stránka přesunuta, přejmenována nebo smazána. Případně mohla být chyba v odkazu, který jste použili, nebo v adrese URL, kterou jste zadali do prohlížeče.'
            : "Hmm, we can't seem to find the page you're looking for. As Seyfor Docs is currently being restructured, the page may have been moved, renamed, or deleted. Alternatively, there may have been an error in the link you followed or the URL you entered into your browser."}
        </div>
        <a href='/' className='btn btn-secondary'>
          {languageService.getLanguage() == 'cs-cz'
            ? 'Zpět na hlavní stránku'
            : 'Go to home page'}
        </a>
      </div>
    </div>
  );
};

export default NotFoundPage;

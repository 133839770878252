import React from 'react';

//custom components import
import ActionButtons from '../../../global-components/ActionButtons';

//custom interface import
import { I_TileComponent } from '../../../providers/interfaces/interfaces';

//lib import
import ReactMarkdown from 'react-markdown';
import remarkBreaks from 'remark-breaks';
import remarkToc from 'remark-toc';
import remarkGfm from 'remark-gfm';
import remarkLint from 'remark-lint';
import CircularProgress from '@mui/material/CircularProgress';
import rehypeHighlight from 'rehype-highlight';

const TitleComponent: React.FunctionComponent<I_TileComponent> = (
  props: I_TileComponent
) => {
  const getDescription = (): JSX.Element => {
    if (props.description == undefined) {
      return <CircularProgress />;
    }
    return (
      <ReactMarkdown
        remarkPlugins={[remarkBreaks, remarkGfm, remarkToc, remarkLint]}
        rehypePlugins={[rehypeHighlight]}
      >
        {props?.description?.toString()}
      </ReactMarkdown>
    );
  };

  return (
    <>
      <div id='title-component'>
        <div className='container-fluid'>
          <ActionButtons />
          <div className='main-title'>{props.title}</div>
          <div className='main-description'>{getDescription()}</div>
        </div>
      </div>
    </>
  );
};

export default TitleComponent;

import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { HomeProvider } from '../providers/API-providers/Home-provider';
import { PageConfiguration } from '../providers/interfaces/interfaces';
import { LanguageService } from '../providers/Language-services/Language-service';
import Home from './home/Home';
import Wiki from './wiki/Wiki';

const languageService = new LanguageService();
const homeProvider = new HomeProvider();

const InitPage: React.FC = () => {
  const params = useParams();
  const [pageConfig, setPageConfig] = useState<PageConfiguration>();
  const path = decodeURIComponent(window.location.pathname).replaceAll(
    '-',
    ' '
  );
  useEffect(() => {
    if (path.charAt(path.length - 1) == '/') {
      location.replace(path.slice(-1));
    }
    if (
      params.lang != languageService.getLanguage() &&
      params.lang != undefined &&
      (params.lang == 'en-us' || params.lang == 'cs-cz')
    ) {
      languageService.setLanguage(params.lang);
      location.reload();
    }
    const customLink = checkIfCustomLink(path);
    prefetchProjects(customLink);
  }, []);

  const checkIfCustomLink = (link: string): string => {
    const linkQueryArray = link.split('/');
    linkQueryArray.shift();
    console.log(linkQueryArray);
    if (linkQueryArray && linkQueryArray.length > 1) {
      linkQueryArray.shift();
      console.log(linkQueryArray);
      link = linkQueryArray.join('/');
      return '/' + link;
    }
    return '';
  };

  const prefetchProjects = (customLink?: string): void => {
    const link = customLink ?? '';
    if (params.lang == undefined) return;
    homeProvider
      .fetchProjects(params.lang + link)
      .then((result) => {
        if (!result.ok) throw result;
        return result.json();
      })
      .then((data) => {
        if (data.TableOfContent == undefined) throw '400 bad data';
        console.log(data);
        setPageConfig({
          IsInit: data.IsInit,
          IsValidPath: data.IsValidPath,
          breadcrumbIndex: data.InitPageIndex,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getPageView = useMemo(() => {
    if (!pageConfig?.IsInit) {
      return <Wiki customBreadcrumbIndex={pageConfig?.breadcrumbIndex} />;
    }
    return <Home />;
  }, [pageConfig]);

  return <div className='init-page'>{getPageView}</div>;
};

export default InitPage;

import React, { useEffect } from 'react';
//styles import
import './App.css';

//lib import
import $ from 'jquery';
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from 'react-router-dom';

//components import
import InitPage from './pages/InitPage';
import { LanguageService } from './providers/Language-services/Language-service';
import { ThemeService } from './providers/Theme-services/Theme-service';

const languageSerivce = new LanguageService();
const themeService = new ThemeService();

const App: React.FunctionComponent = () => {
  //initial theme setting
  useEffect(() => {
    $('html').addClass(themeService.getTheme());
  }, []);

  const element = (
    <Router>
      <Routes>
        <Route element={<InitPage />} path='/:lang/:articlePath/*' />
        <Route element={<InitPage />} path='/:lang' />

        <Route
          element={<Navigate to={'/' + languageSerivce.getLanguage()} />}
          path='/'
        />

        <Route
          element={<Navigate to={'/' + languageSerivce.getLanguage()} />}
          path={'/' + languageSerivce.getLanguage() + '/Apps'}
        />
      </Routes>
    </Router>
  );

  return element;
};

export default App;

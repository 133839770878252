import Link from '@mui/material/Link';
import React, { useMemo } from 'react';
import SeyforLogo from '../assets/images/logos/solitea-logo';
import { LanguageService } from '../providers/Language-services/Language-service';
import './Components.css';

const languageSerivce = new LanguageService();
const Navbar: React.FunctionComponent = () => {
  const path = decodeURIComponent(window.location.pathname).replaceAll(
    '-',
    ' '
  );

  const checkIfCustomLink = (link: string): string => {
    const linkQueryArray = link.split('/');
    linkQueryArray.shift();
    console.log(linkQueryArray);
    if (linkQueryArray && linkQueryArray.length > 1) {
      return linkQueryArray[1];
    }
    return '';
  };

  const customLink = useMemo(() => {
    return checkIfCustomLink(path);
  }, [path]);

  return (
    <>
      <nav className='navbar navbar-expand-lg navbar-light'>
        <a className='navbar-brand' href='/'>
          <SeyforLogo />
        </a>
        <button
          className='navbar-toggler'
          type='button'
          data-toggle='collapse'
          data-target='#navbarSupportedContent'
          aria-controls='navbarSupportedContent'
          aria-expanded='false'
          aria-label='Toggle navigation'
        >
          <span className='navbar-toggler-icon'></span>
        </button>

        <div className='collapse navbar-collapse' id='navbarSupportedContent'>
          <ul className='navbar-nav mr-auto'>
            <li className='nav-item'>
              <Link
                className='nav-link'
                href={`/${languageSerivce.getLanguage()}/${customLink}`}
                id='docs-link'
              >
                <div className='nav-link-text'>{customLink}</div>
                <span className='sr-only'>(current)</span>
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
};

export default Navbar;

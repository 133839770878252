import React from 'react';

const SeyforLogo: React.FunctionComponent = () => {
  return (
    <>
      <svg
        version='1.0'
        xmlns='http://www.w3.org/2000/svg'
        width='4360.000000pt'
        height='1420.000000pt'
        viewBox='0 0 4360.000000 1420.000000'
        preserveAspectRatio='xMidYMid meet'
      >
        <g
          transform='translate(0.000000,1420.000000) scale(0.100000,-0.100000)'
          fill='#000000'
          stroke='none'
        >
          <path
            d='M4305 13239 c-530 -36 -1045 -157 -1457 -340 -657 -292 -1151 -774
-1406 -1369 -170 -397 -241 -802 -229 -1305 8 -323 37 -527 113 -795 275 -970
1082 -1680 2250 -1978 322 -83 539 -114 1164 -167 571 -48 730 -70 971 -131
442 -111 693 -310 770 -612 24 -96 26 -289 3 -399 -75 -364 -384 -634 -869
-758 -238 -61 -332 -70 -695 -70 -351 0 -453 10 -675 62 -592 138 -967 522
-1003 1028 l-7 90 -1164 3 -1164 2 7 -132 c25 -488 116 -856 306 -1243 125
-254 290 -490 490 -699 329 -343 823 -648 1336 -826 599 -207 1195 -289 2004
-277 661 10 1130 80 1631 243 829 270 1441 741 1808 1391 247 439 369 1016
333 1575 -46 713 -279 1232 -768 1708 -157 153 -257 233 -439 355 -303 201
-601 342 -1006 475 -450 149 -884 230 -1524 285 -165 14 -374 32 -465 41 -500
46 -776 174 -948 437 -55 84 -102 196 -123 292 -20 91 -17 304 5 399 95 412
428 670 936 726 153 17 441 7 574 -20 356 -71 630 -255 792 -530 84 -142 130
-291 142 -460 l7 -95 1158 -3 c1023 -2 1158 0 1163 13 9 24 -15 373 -36 514
-77 527 -282 1013 -591 1399 -91 113 -280 304 -394 397 -573 465 -1284 715
-2210 775 -183 11 -613 11 -790 -1z'
          />
          <path
            d='M27840 13109 c-755 -56 -1347 -268 -1757 -628 -441 -389 -683 -1020
-683 -1783 l0 -168 -397 -2 -398 -3 -3 -852 -2 -853 400 0 400 0 0 -2655 0
-2655 1083 2 1082 3 3 2652 2 2653 853 2 852 3 0 850 0 850 -852 3 -853 2 0
173 c0 201 13 274 66 384 64 134 186 232 349 280 53 15 123 17 623 21 l562 3
0 865 0 864 -612 -1 c-337 -1 -660 -5 -718 -10z'
          />
          <path
            d='M12705 10929 c-1265 -79 -2306 -721 -2904 -1792 -262 -468 -422
-1002 -471 -1562 -15 -180 -12 -752 5 -928 85 -880 465 -1691 1079 -2303 186
-185 301 -279 511 -419 487 -324 984 -502 1625 -582 208 -25 812 -26 1020 0
568 69 1033 223 1460 482 597 362 1049 904 1288 1545 42 114 103 318 126 423
l6 27 -1068 0 -1069 0 -52 -85 c-139 -228 -369 -389 -673 -470 -262 -70 -653
-80 -933 -24 -476 96 -809 378 -995 841 -46 115 -110 335 -110 379 0 19 36 19
2516 19 l2516 0 -5 528 c-3 399 -9 562 -21 671 -88 767 -343 1412 -782 1981
-110 142 -392 427 -539 544 -569 452 -1191 679 -1990 726 -192 11 -350 11
-540 -1z m588 -1903 c451 -83 770 -359 956 -828 33 -85 84 -265 105 -377 l14
-71 -1414 0 c-1139 0 -1414 2 -1414 13 0 7 13 66 29 132 156 634 529 1020
1089 1125 148 27 164 28 367 25 109 -2 214 -9 268 -19z'
          />
          <path
            d='M33365 10930 c-1062 -66 -1961 -443 -2611 -1097 -677 -680 -1034
-1582 -1034 -2615 l0 -118 1174 0 1173 0 7 133 c30 563 196 995 500 1303 219
221 459 349 771 411 72 14 139 18 335 18 216 -1 257 -4 350 -24 299 -64 532
-191 740 -402 266 -269 431 -645 485 -1106 19 -166 19 -490 0 -657 -58 -498
-256 -912 -555 -1161 -250 -209 -525 -309 -912 -331 l-118 -7 0 -978 0 -979
128 0 c377 0 856 68 1224 174 1490 428 2458 1599 2588 3131 17 199 8 960 -13
1113 -62 446 -169 810 -343 1167 -519 1068 -1552 1791 -2831 1980 -292 43
-764 63 -1058 45z'
          />
          <path
            d='M42100 10769 c-248 -25 -556 -112 -763 -214 -199 -98 -383 -232 -533
-388 -404 -417 -657 -992 -769 -1742 l-23 -160 -1 1208 -1 1207 -927 -2 -928
-3 0 -3580 0 -3580 1172 -3 1172 -2 4 1927 c3 2115 0 1989 63 2241 132 533
494 894 1029 1027 195 49 285 57 703 62 l392 5 0 1004 0 1004 -252 -1 c-139
-1 -291 -6 -338 -10z'
          />
          <path
            d='M16734 10663 c3 -10 465 -1559 1026 -3443 561 -1884 1025 -3440 1031
-3458 l11 -32 769 0 c729 0 770 -1 765 -17 -3 -10 -19 -81 -36 -158 -67 -304
-141 -465 -277 -602 -70 -70 -97 -89 -185 -132 -223 -108 -252 -111 -1325
-111 l-883 0 0 -980 0 -980 868 0 c1011 0 1206 10 1612 76 1118 184 1675 698
2034 1880 41 132 1956 7930 1956 7961 0 11 -214 13 -1107 11 l-1108 -3 -571
-2565 -571 -2565 -114 -3 -115 -3 -703 2518 c-388 1385 -711 2542 -719 2571
l-15 52 -1174 0 c-1114 0 -1174 -1 -1169 -17z'
          />
          <path
            d='M29810 4375 l0 -1055 1095 0 1095 0 0 1055 0 1055 -1095 0 -1095 0 0
-1055z'
          />
        </g>
      </svg>
    </>
  );
};

export default SeyforLogo;

import React from 'react';

//custom interface import
import { I_SingleProject } from '../../../providers/interfaces/interfaces';

//service import
import { LanguageService } from '../../../providers/Language-services/Language-service';

const languageService = new LanguageService();

const SingleProject: React.FunctionComponent<I_SingleProject> = (
  props: I_SingleProject
) => {
  return (
    <div className='single-project-parrent col-12 col-sm-6 col-lg-3 col-md-4'>
      <div className='single-project'>
        <h3>{props.projectName}</h3>
        <a href={props.projectLink}>
          {languageService.getLanguage() == 'cs-cz'
            ? 'Dokumentace '
            : 'Documentation'}
        </a>
      </div>
    </div>
  );
};

export default SingleProject;

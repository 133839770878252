import { APIservice } from '../API-services/Request-service';

export class WikiProvider {
  private apiService = new APIservice();

  fetchWikiTree(path: string): Promise<Response> {
    return this.apiService.get(path);
  }

  fetchWikiContent(path: string): Promise<Response> {
    return this.apiService.get(path);
  }
}

import React from 'react';
import { useState, useEffect } from 'react';

//lib import
import { TreeView } from '@mui/lab';
import TreeItem from '@mui/lab/TreeItem';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Drawer from '@mui/material/Drawer';

//icon import
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TocIcon from '@mui/icons-material/Toc';
import CloseIcon from '@mui/icons-material/Close';

//service import
import { LanguageService } from '../../../providers/Language-services/Language-service';

//interfaces import
import { I_TreeView } from '../../../providers/interfaces/interfaces';

const languageService = new LanguageService();
let linkOpenElementsNames = new Array<string>();
let selectedElementName: string;
const TreeViewArea: React.FunctionComponent<I_TreeView> = (
  props: I_TreeView
) => {
  const [expanded, setExpanded] = useState<string[]>([]);
  const [selected, setSelected] = useState<string[]>([]);
  const [drawerVisibility, setDrawerVisibility] = useState({
    left: false,
  });
  const navigator = useNavigate();
  let pageURL = decodeURIComponent(window.location.pathname);
  pageURL = pageURL.replaceAll('-', ' ');
  let currentnodeID = -1;
  const linkOpenedNodeIds = new Array<string>();
  const selectedNodeId = new Array<string>();
  useEffect(() => {
    linkOpenElementsNames = getLinkArray(pageURL);
    selectedElementName = getTitle(pageURL);
  }, [pageURL]);

  useEffect(() => {
    if (
      linkOpenElementsNames.length > 0 &&
      selectedNodeId.length > 0 &&
      expanded.length === 0 &&
      selected.length === 0
    ) {
      handleExpandClick(linkOpenedNodeIds);
      handleSelectClick(selectedNodeId);
    }
  }, [linkOpenedNodeIds]);

  //tree handlers
  const handleToggle = (event: React.SyntheticEvent, nodeIds: string[]) => {
    setExpanded(nodeIds);
  };

  const handleSelect = (event: React.SyntheticEvent, nodeIds: string[]) => {
    setSelected(nodeIds);
  };

  const handleExpandClick = (expandArray: string[]) => {
    setExpanded((oldExpanded) => (oldExpanded.length === 0 ? expandArray : []));
  };

  const handleSelectClick = (selectArray: string[]) => {
    setSelected((oldSelected) => (oldSelected.length === 0 ? selectArray : []));
  };
  //END tree handlers

  const getLinkArray = (pageURL: string): string[] => {
    const linkArray = pageURL.split('/');
    return linkArray.filter((link) => link != '').splice(2);
  };

  const getTitle = (link: string): string => {
    link = link.replaceAll('-', ' ');
    let linkArray = link.split('/');
    linkArray = linkArray.filter((val) => val != '');
    return linkArray[linkArray.length - 1];
  };

  const getProjectNameFromLink = (link: string): string => {
    link = link.replaceAll('-', ' ');
    let linkArray = link.split('/');
    linkArray = linkArray.filter((val) => val != '');
    return linkArray[1];
  };

  const toggleDrawer = (anchor: string, open: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent
  ) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    setDrawerVisibility({ ...drawerVisibility, [anchor]: open });
  };

  const increaseCurrentNodeID = () => {
    currentnodeID += 1;
  };

  const renderTree = (treeView: I_TreeView): JSX.Element => {
    increaseCurrentNodeID();
    if (treeView.SubPage.length > 0) {
      if (
        linkOpenElementsNames.find((el) => el == getTitle(treeView.Path)) !=
        undefined
      ) {
        linkOpenedNodeIds.push(currentnodeID.toString());
      }
      //render overview if Content not ''

    //OVERVIEW

     // if (treeView.Content != '') {
     //   return (
     //     <TreeItem
     //       key={currentnodeID}
     //       nodeId={currentnodeID.toString()}
     //       label={getTitle(treeView.Path)}
     //       className={'tree-item-' + currentnodeID}
     //     >
     //       {increaseCurrentNodeID()}
     //       <TreeItem
     //         key={currentnodeID}
     //         nodeId={currentnodeID.toString()}
     //         label={
     //           languageService.getLanguage() == 'cs-cz'
     //             ? 'Přehled'
     //             : 'Overview'
     //         }
     //         className={'tree-item-' + currentnodeID}
     //         onClick={() => {
     //           navigator('/' + languageService.getLanguage() + treeView.Path);
     //         }}
     //       ></TreeItem>
//
     //       {treeView.SubPage.length > 0
     //         ? treeView.SubPage.map((node) => renderTree(node))
     //         : null}
     //     </TreeItem>
     //   );
     // }

     //END OVERVIEW
     
      return (
        <TreeItem
          key={currentnodeID}
          nodeId={currentnodeID.toString()}
          label={getTitle(treeView.Path)}
          className={'tree-item-' + currentnodeID}
        >
          {treeView.SubPage.length > 0
            ? treeView.SubPage.map((node) => renderTree(node))
            : null}
        </TreeItem>
      );
    } else {
      if (selectedElementName === getTitle(treeView.Path)) {
        selectedNodeId.push(currentnodeID.toString());
      }
      return (
        <TreeItem
          key={currentnodeID}
          nodeId={currentnodeID.toString()}
          label={getTitle(treeView.Path)}
          className={'tree-item-' + currentnodeID}
          onClick={() => {
            navigator('/' + languageService.getLanguage() + treeView.Path);
          }}
        >
          {treeView.SubPage.length > 0
            ? treeView.SubPage.map((node) => renderTree(node))
            : null}
        </TreeItem>
      );
    }
  };

  //show loader if tree not fetched
  if (props.Path == '') {
    return (
      <>
        <CircularProgress color='inherit' />
      </>
    );
  }

  return (
    <>
      <div id='tree-view'>
        <div
          className={
            selected.length === 0 ? 'welcome-div Mui-selected' : 'welcome-div'
          }
          onClick={() => {
            setSelected([]);
            navigator('/' + languageService.getLanguage() + props.Path);
          }}
        >
          {languageService.getLanguage() == 'cs-cz'
            ? 'Vítejte v ' + getProjectNameFromLink(props.Path)
            : 'Welcome to ' + getProjectNameFromLink(props.Path)}
        </div>
        <TreeView
          aria-label='file system navigator'
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
          expanded={expanded}
          selected={selected}
          onNodeToggle={handleToggle}
          onNodeSelect={handleSelect}
          sx={{ height: 600, flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}
        >
          {props?.SubPage.length > 0 ? renderTree(props?.SubPage[0]) : null}
        </TreeView>
      </div>
      <div id='modal-tree-view'>
        <button
          className='drawer-open-button'
          onClick={toggleDrawer('left', true)}
        >
          <TocIcon />{' '}
          {languageService.getLanguage() == 'cs-cz' ? 'Obsah' : 'Content'}
        </button>
        <Drawer
          anchor={'left'}
          open={drawerVisibility['left']}
          onClose={toggleDrawer('left', false)}
        >
          <div className='drawer-header d-flex'>
            <div className='drawer-title'>
              {languageService.getLanguage() == 'cs-cz'
                ? 'Tabulka obsahu'
                : 'Table of contents'}
            </div>

            <button
              className='drawer-open-button ml-auto'
              onClick={toggleDrawer('left', false)}
            >
              <CloseIcon />
            </button>
          </div>
          <div className='drawer-body'>
            <div
              className={
                selected.length === 0
                  ? 'welcome-div Mui-selected'
                  : 'welcome-div '
              }
            >
              {languageService.getLanguage() == 'cs-cz'
                ? 'Vítejte v ' + getProjectNameFromLink(props.Path)
                : 'Welcome to ' + getProjectNameFromLink(props.Path)}
            </div>
            <TreeView
              aria-label='file system navigator'
              defaultCollapseIcon={<ExpandMoreIcon />}
              defaultExpandIcon={<ChevronRightIcon />}
              expanded={expanded}
              selected={selected}
              onNodeToggle={handleToggle}
              onNodeSelect={handleSelect}
              sx={{
                height: 600,
                flexGrow: 1,
                maxWidth: 400,
                overflowY: 'auto',
              }}
            >
              {props?.SubPage.length > 0 ? renderTree(props?.SubPage[0]) : null}
            </TreeView>
          </div>
        </Drawer>
      </div>
    </>
  );
};

export default TreeViewArea;

//service imports
import { APIservice } from '../API-services/Request-service';
import { LanguageService } from '../Language-services/Language-service';

export class HomeProvider {
  private apiService = new APIservice();
  private languageService = new LanguageService();
  fetchProjects(path: string): Promise<Response> {
    return this.apiService.get(path);
  }

  getMainTitle(path: string): Promise<Response> {
    return this.apiService.get(path);
  }

  fetchResouces(): Promise<Response> {
    return this.apiService.get(
      'resources?lang=' + this.languageService.getLanguage()
    );
  }
}

import React from 'react';
import { useEffect, useState } from 'react';

//provider import
import { HomeProvider } from '../providers/API-providers/Home-provider';
import { LanguageService } from '../providers/Language-services/Language-service';

const homeProvider = new HomeProvider();
const languageService = new LanguageService();

const Footer: React.FunctionComponent = () => {
  const [resourceLinks, setResourceLinks] = useState<string[]>([]);
  useEffect(() => {
    fetchResources();
  }, []);

  const getTitle = (link: string): string => {
    link = link.replaceAll('-', ' ');
    let linkArray = link.split('/');
    linkArray = linkArray.filter((val) => val != '');
    return linkArray[linkArray.length - 1];
  };

  const fetchResources = () => {
    homeProvider
      .fetchResouces()
      .then((response) => {
        if (!response.ok) throw response.statusText;
        return response.json();
      })
      .then((data) => {
        data.Paths.forEach((element: string) => {
          setResourceLinks((resourceLinks) => [...resourceLinks, element]);
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const mapResources = (): JSX.Element[] => {
    return resourceLinks.map((element: string, key: number) => {
      return (
        <li key={key} className='footer-resource'>
          <a href={'/' + languageService.getLanguage() + element}>
            {getTitle(element)}
          </a>
        </li>
      );
    });
  };

  return (
    <>
      <footer id='footer'>
        <div className='footer-inner outer-padding'>
          <ul>
            {mapResources()}
            <li>© 2022 Seyfor a.s.</li>
          </ul>
        </div>
      </footer>
    </>
  );
};

export default Footer;

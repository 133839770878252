//enviroment import
import { environment } from '../../enviroments/enviroment';

//service import
export class APIservice {
  async post(url: string, searchData?: string): Promise<Response> {
    return fetch(environment.API_ENDPOINT + url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: searchData,
    });
  }

  async get(url: string, searchData?: string): Promise<Response> {
    return fetch(environment.API_ENDPOINT + url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      body: searchData,
    });
  }
}

import React, { useEffect, useState } from 'react';

//css styles import
import './Home.css';

//import libs
import { useParams } from 'react-router-dom';

//custom components import
import Footer from '../../global-components/Footer';
import Navbar from '../../global-components/Navbar';
import { HomeProvider } from '../../providers/API-providers/Home-provider';
import { LanguageService } from '../../providers/Language-services/Language-service';
import ProjectContainer from './Components/Project-container';
import TitleComponent from './Components/Title-component';

//custom interface import
import { I_SingleProject } from '../../providers/interfaces/interfaces';

const homeProvider = new HomeProvider();
const languageService = new LanguageService();

const Home: React.FunctionComponent = () => {
  const [projects, setProjects] = useState<I_SingleProject[]>([]);
  const [description, setDescription] = useState<string>('');
  const params = useParams();
  let path = decodeURIComponent(window.location.pathname);
  path = path.replaceAll('-', ' ');
  useEffect(() => {
    if (path.charAt(path.length - 1) == '/') {
      location.replace(path.slice(-1));
    }
    if (
      params.lang !== languageService.getLanguage() &&
      params.lang !== undefined &&
      (params.lang === 'en-us' || params.lang === 'cs-cz')
    ) {
      languageService.setLanguage(params.lang);
      location.reload();
    }

    const customLink = checkIfCustomLink(path);

    if (customLink !== '') {
      fetchProjects(customLink);
    } else {
      fetchProjects();
    }
  }, []);

  const getTitle = (link: string): string => {
    link = link.replaceAll('-', ' ');
    let linkArray = link.split('/');
    linkArray = linkArray.filter((val) => val != '');
    return linkArray[linkArray.length - 1];
  };

  const checkIfCustomLink = (link: string): string => {
    const linkQueryArray = link.split('/');
    linkQueryArray.shift();
    if (linkQueryArray && linkQueryArray.length > 1) {
      linkQueryArray.shift();
      link = linkQueryArray.join('/');
      return '/' + link;
    }
    return '';
  };

  const fetchProjects = (customLink?: string): void => {
    const link = customLink ?? '';
    if (params.lang == undefined) return;
    homeProvider
      .fetchProjects(params.lang + link)
      .then((result) => {
        if (!result.ok) throw result;
        return result.json();
      })
      .then((data) => {
        if (data.TableOfContent == undefined) throw '400 bad data';
        const projectsStringArray = data.TableOfContent[0].SubPage;
        setDescription(data?.Content);
        projectsStringArray.forEach((element: any) => {
          console.log(languageService.getLanguage() + element.Path);
          setProjects((projects) => [
            ...projects,
            {
              projectName: getTitle(element.Path),
              projectLink: '/' + languageService.getLanguage() + element.Path,
            },
          ]);
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <>
      <div id='home'>
        <Navbar />
        <TitleComponent title={'Seyfor Docs'} description={description} />
        <ProjectContainer projectArray={projects} />
        <Footer />
      </div>
    </>
  );
};

export default Home;

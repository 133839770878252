import React from 'react';

//library import
import $ from 'jquery';

//icons import
import LightModeIcon from '@mui/icons-material/LightMode';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import PublicIcon from '@mui/icons-material/Public';

//services import
import { LanguageService } from '../providers/Language-services/Language-service';
import { ThemeService } from '../providers/Theme-services/Theme-service';

const languageService = new LanguageService();
const themeService = new ThemeService();

const ActionButtons: React.FunctionComponent = () => {
  const setLanguage = (lang: string) => {
    languageService.setLanguage(lang);
    const secondPart = window.location.href.split('/')[4]
      ? '/' + window.location.href.split('/')[4]
      : '';
    location.replace('/' + languageService.getLanguage() + secondPart);
  };

  const setTheme = (theme: string) => {
    themeService.setTheme(theme);
    $('html').removeClass();
    $('html').addClass(theme);
  };

  return (
    <>
      <div id='action-buttons'>
        <div className='d-flex text-right'>
          <div className='dropdown text-right'>
            <button
              className='btn btn-primary'
              type='button'
              id='dropdownTheme'
              data-toggle='dropdown'
              aria-haspopup='true'
              aria-expanded='false'
            >
              <LightModeIcon />
              <div className='action-text'>
                {languageService.getLanguage() == 'cs-cz'
                  ? 'Tmavý/Světlý režim'
                  : 'Themes'}
              </div>
            </button>
            <div className='dropdown-menu' aria-labelledby='dropdownTheme'>
              <a
                className='dropdown-item'
                onClick={() => {
                  setTheme('theme-light');
                }}
              >
                {languageService.getLanguage() == 'cs-cz'
                  ? 'Světlý režim'
                  : 'Light mode'}
              </a>
              <a
                className='dropdown-item'
                onClick={() => {
                  setTheme('theme-dark');
                }}
              >
                {languageService.getLanguage() == 'cs-cz'
                  ? 'Tmavý režim'
                  : 'Dark mode'}
              </a>
              <a
                className='dropdown-item'
                onClick={() => {
                  setTheme('theme-high-contrast');
                }}
              >
                {languageService.getLanguage() == 'cs-cz'
                  ? 'Režim vysokého kontrastu'
                  : 'High contrast mode'}
              </a>
            </div>
          </div>

          <div className=' text-right'>
            <button
              className='btn btn-primary'
              onClick={() => {
                window.print();
              }}
            >
              <LocalPrintshopIcon />
              <div className='action-text'>
                {languageService.getLanguage() == 'cs-cz'
                  ? 'Tisknout'
                  : 'Print'}
              </div>
            </button>
          </div>

          <div className='dropdown text-right'>
            <button
              className='btn btn-primary'
              type='button'
              id='dropdownLanguage'
              data-toggle='dropdown'
              aria-haspopup='true'
              aria-expanded='false'
            >
              <PublicIcon />
              <div className='action-text'>
                {languageService.getLanguage() == 'cs-cz'
                  ? 'Jazyk'
                  : 'Language'}
              </div>
            </button>
            <div className='dropdown-menu' aria-labelledby='dropdownLanguage'>
              <a
                className='dropdown-item'
                onClick={() => {
                  setLanguage('cs-cz');
                }}
              >
                Česky
              </a>
              <a
                className='dropdown-item'
                onClick={() => {
                  setLanguage('en-us');
                }}
              >
                English
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ActionButtons;

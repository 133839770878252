import React from 'react';

//lib import
import ReactMarkdown from 'react-markdown';
import remarkBreaks from 'remark-breaks';
import remarkToc from 'remark-toc';
import remarkGfm from 'remark-gfm';
import remarkLint from 'remark-lint';
import remarkEmoji from 'remark-emoji';
import rehypeHighlight from 'rehype-highlight';

//custom interface import
import { I_MarkdownComponent } from '../../../providers/interfaces/interfaces';

//service import
import { LanguageService } from '../../../providers/Language-services/Language-service';

const languageService = new LanguageService();
let linkArray: string[];
let lastItem = '';

const MarkdownComponent: React.FunctionComponent<I_MarkdownComponent> = (
  props: I_MarkdownComponent
) => {
  const getLinkArray = (): void => {
    linkArray = props.pageURL.split('/');
    linkArray = linkArray.filter((link) => link != '');
  };

  const getTitle = (): string => {
    getLinkArray();
    lastItem = linkArray[linkArray.length - 1];
    return lastItem;
  };

  const countAvgReadTime = (): string => {
    const wordArray = props.markdown.split(' ');
    return (
      Math.ceil(wordArray.length / 170) +
      (languageService.getLanguage() == 'cs-cz'
        ? ' minut čtení'
        : ' minutes to read')
    );
  };

  return (
    <>
      <div id='markdown-component'>
        <div id='article-title'>{getTitle()}</div>
        <div id='avg-read-time'>{countAvgReadTime()}</div>
        <ReactMarkdown
          remarkPlugins={[
            remarkBreaks,
            remarkEmoji,
            remarkGfm,
            remarkToc,
            remarkLint,
          ]}
          rehypePlugins={[rehypeHighlight]}
        >
          {props.markdown}
        </ReactMarkdown>
      </div>
    </>
  );
};

export default MarkdownComponent;

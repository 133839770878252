import { StorageManager } from '../API-providers/Storage-services/Storage-service';

export class LanguageService {
  private storageManager = new StorageManager();

  setLanguage(lang: string): void {
    this.storageManager.setLocalStorage('lang', lang);
  }

  getLanguage(): string {
    const userLang = navigator.language.toLowerCase();
    if (userLang && userLang == 'cs-cz')
      return this.storageManager.getLocalStorage('lang', 'cs-cz').toString();

    return this.storageManager.getLocalStorage('lang', 'en-us').toString();
  }
}

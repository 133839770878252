import { StorageManager } from '../API-providers/Storage-services/Storage-service';

export class ThemeService {
  storageManager = new StorageManager();

  getTheme(): string {
    return this.storageManager
      .getLocalStorage('theme', 'theme-light')
      .toString();
  }

  setTheme(theme: string): void {
    this.storageManager.setLocalStorage('theme', theme);
  }
}
